// External Dependencies
import React from 'react';
import PropTypes from 'prop-types';

// Internal Dependencies
import './admin-styles.css';

class PostsGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      postsArray: [],
    };
  }

  componentDidMount() {
    let args = [
      this.props.posts_number,
      this.props.posts_per_row,
      this.props.post_type,
      this.props.category,
    ];
    let str = `/wp-json/myposts/v1/my_posts/${args}`;

    fetch(str, {
      method: 'GET',
    })
      .then((response) => {
        if (response.ok && response.status === 200) {
          return response.json();
        }
        throw new Error(response.statusText);
      })
      .then((response) => {
        //console.log(response);
        this.setState({
          postsArray: response,
        });
      })
      .catch((error) => {
        if (process.env.NODE_ENV === 'development') {
          console.error(error); //eslint-disable-line
        }
      });
  }

  render() {
    const { post_type, posts_per_row } = this.props;

    let per_row = 12 / posts_per_row;

    return (
      <div className="grid-container full">
        <div
          className={`grid-x grid-margin-x ${
            post_type === 'hot_tubs' ? 'hot_tubs' : 'hot-tube-grid'
          }`}
        >
          {this.state.postsArray.map((post, key) => (
            <div
              key={key}
              className={`cell medium-6 large-${per_row} ${
                post_type === 'hot_tubs' ? 'hot-tube' : 'post-item'
              }`}
            >
              <article>
                <div className="preview__wrapper">
                  <div className="preview__header">
                    <img src={post.image} className="of-cover" />
                    <h2 className="preview__title">{post.title}</h2>
                  </div>
                  <div className="preview__divider" />
                  <div className="preview__content">
                    <p className="preview__date">{post.date}</p>
                    <p className="preview__excerpt">{post.excerpt}</p>
                    <a href="#" className="button button--round">
                      Read More
                    </a>
                  </div>
                </div>
              </article>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

PostsGrid.propTypes = {
  posts_number: PropTypes.string,
  posts_per_row: PropTypes.string,
  post_type: PropTypes.string,
  category: PropTypes.string,
};

PostsGrid.slug = 'fwp_posts_grid';

export default PostsGrid;
