// Internal Dependencies
import TestimonialsSlider from './testimonials-slider';

$(window).on('et_builder_api_ready', (event, API) => {
  API.registerModules([TestimonialsSlider]);
});

$(document).on('ready', function () {
  let $testimonialsSlider = $('.js-testimonials-slider');

  if ($testimonialsSlider.length) {
    $testimonialsSlider.each(function () {
      let arrows = $(this).attr('data-arrows');
      let dots = $(this).attr('data-dots');

      $(this).slick({
        cssEase: 'ease',
        arrows: arrows == 'on' ? true : false,
        dots: dots == 'on' ? true : false,
        fade: false, // Cause trouble if used slidesToShow: more than one
        infinite: true,
        speed: 500,
        pauseOnHover: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        rows: 0, // Prevent generating extra markup
      });
    });
  }
});
