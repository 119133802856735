// External Dependencies
import React from 'react';
import PropTypes from 'prop-types';

// Internal Dependencies
import './admin-styles.css';

class imagesPanorama extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      images: [],
      isLoaded: false,
    };
  }

  componentDidMount() {
    let str = `/wp-json/myimages/v1/my_images/${this.props.gallery_ids}`;

    fetch(str, {
      method: 'GET',
    })
      .then((response) => {
        if (response.ok && response.status === 200) {
          return response.json();
        }
        throw new Error(response.statusText);
      })
      .then((response) => {
        this.setState({
          isLoaded: true,
          images: response,
        });
      })
      .catch((error) => {
        if (process.env.NODE_ENV === 'development') {
          console.error(error); //eslint-disable-line
        }
      });
  }

  render() {
    return (
      <div className="images-panorama">
        {this.state.images.map((image, key) => (
          <img key={key} src={image} alt="" />
        ))}
      </div>
    );
  }
}

imagesPanorama.propTypes = {
  gallery_ids: PropTypes.array,
};

imagesPanorama.slug = 'images_panorama';

export default imagesPanorama;
