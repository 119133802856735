// External Dependencies
import React from 'react';
import PropTypes from 'prop-types';

class TeaserBox extends React.Component {
  render() {
    const { image, image_size, title, content, button_url, button_text } =
      this.props;

    return (
      <div className="teaser-box">
        <div
          className={`teaser-box__image ${
            image_size === 'on' ? 'teaser-box__image--large' : false
          }`}
        >
          {image && <img src={image} alt="" />}
          <h2 className="teaser-box__title">{title}</h2>
        </div>
        <div className="teaser-box__divider" />
        <div className="teaser-box__text">
          {content()}
          <div data-mh="teaser-box-text" />
          {button_url && (
            <div className="text-center">
              <a className="button button--round" href={button_url}>
                {button_text ? button_text : 'Read More'}
              </a>
            </div>
          )}
        </div>
      </div>
    );
  }
}

TeaserBox.propTypes = {
  image: PropTypes.string,
  image_size: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.any,
  button_url: PropTypes.string,
  button_text: PropTypes.string,
};

TeaserBox.slug = 'fwp_teaser_box';

export default TeaserBox;
