// External Dependencies
import React from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';

class TestimonialsSlider extends React.Component {
  render() {
    const { content } = this.props;

    const settings = {
      dots: false,
      arrows: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

    return (
      <Slider className="testimonials-slider" {...settings}>
        {content.map((one, key) => (
          <div key={key} className="logos__item text-center matchHeight">
            <div className="testimonials__slide">
              <div className="testimonials__slide-wrap">
                <div className="testimonials__rating">
                  <span>
                    <i className="fas fa-star"></i>
                  </span>
                  <span>
                    <i className="fas fa-star"></i>
                  </span>
                  <span>
                    <i className="fas fa-star"></i>
                  </span>
                </div>
                <blockquote
                  dangerouslySetInnerHTML={{ __html: one.props.content }}
                />
                <p>{one.props.attrs.author}</p>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    );
  }
}

TestimonialsSlider.propTypes = {
  content: PropTypes.any,
};

TestimonialsSlider.slug = 'custom_testimonials_slider';

export default TestimonialsSlider;
