// Import everything from autoload folder
import './autoload/ada-compliance.js'; import './autoload/foundation.js'; // eslint-disable-line

// Import local dependencies
import './plugins/lazyload';
import './plugins/modernizr.min';
import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
// import '@fancyapps/fancybox/dist/jquery.fancybox.min';
// import { jarallax, jarallaxElement } from 'jarallax';
// import ScrollOut from 'scroll-out';

import '../divi-blocks/example-block/index.js'; import '../divi-blocks/gravity-form-block/index.js'; import '../divi-blocks/images-panorama/index.js'; import '../divi-blocks/logos/index.js'; import '../divi-blocks/posts-grid/index.js'; import '../divi-blocks/related-posts/index.js'; import '../divi-blocks/teaser-box/index.js'; import '../divi-blocks/testimonials-slider/index.js'; // eslint-disable-line
import React from "react"; // eslint-disable-line

/**
 * Init foundation
 */
$(document).foundation();

/**
 * Collapse submenu on mobile
 */
function ds_setup_collapsible_submenus() {
  var $menu = $('.et_mobile_menu'),
    top_level_link = '.et_mobile_menu .menu-item-has-children > a';

  $menu.find('a').each(function () {
    $(this).off('click');
    if ($(this).is(top_level_link)) {
      $('<span class="opn">+</span>').appendTo($(this).parent());
    }
    if (!$(this).siblings('.sub-menu').length) {
      $(this).on('click', function () {
        $(this).parents('.mobile_nav').trigger('click');
      });
    } else {
      var $span = $(this).parent().find('span');
      $span.on('click', function (event) {
        event.preventDefault();
        $(this).parent('li').toggleClass('visible');
      });
    }
  });
}

/**
 * Sticky footer
 */
// function stickyFooter() {
//   let footer = $('footer');
//   if (footer.length) {
//     let position = footer.position();
//     let height = $(window).height();
//     height = height - position.top;
//     height = height - footer.outerHeight();
//     if (height > 0) {
//       footer.css({ 'margin-top': height + 'px' });
//     }
//   }
// }

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  $(window)
    .on('toggled.zf.responsiveToggle', function () {
      $('.menu-icon').toggleClass('is-active');
    })
    .on('changed.zf.mediaquery', function () {
      $('.menu-icon').removeClass('is-active');
    });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
        $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });

  resizeVideo();

  // if (!$('body').hasClass('divi_page_et_theme_builder')) {
  //   stickyFooter();
  // }

  // Init collapse submenu
  setTimeout(function () {
    ds_setup_collapsible_submenus();
  }, 700);
});

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here
  resizeVideo();

  // if (!$('body').hasClass('divi_page_et_theme_builder')) {
  //   stickyFooter();
  // }
});

/**
 * Scripts which runs on scrolling
 */
$(window).on('scroll', function () {
  // jQuery code goes here
});
