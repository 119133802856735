// Internal Dependencies
import Logos from './logos';

$(window).on('et_builder_api_ready', (event, API) => {
  API.registerModules([Logos]);
});

$(document).on('ready', function () {
  if ($('.js-logos-slider').length) {
    let $slider = $('.js-logos-slider');
    $slider.each(function () {
      let slides = $(this).attr('data-slides');

      $(this).slick({
        cssEase: 'ease',
        arrows: true,
        dots: false,
        fade: false, // Cause trouble if used slidesToShow: more than one
        infinite: true,
        speed: 500,
        pauseOnHover: true,
        slidesToShow: slides ? slides : 5,
        slidesToScroll: 1,
        rows: 1, // Prevent generating extra markup
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      });
    });
  }
});
