// External Dependencies
import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';

class Logos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      images: [],
      isLoaded: false,
    };
  }

  componentDidMount() {
    let str = `/wp-json/myimages/v1/my_images/${this.props.logos}`;

    fetch(str, {
      method: 'GET',
    })
      .then((response) => {
        if (response.ok && response.status === 200) {
          return response.json();
        }
        throw new Error(response.statusText);
      })
      .then((response) => {
        this.setState({
          isLoaded: true,
          images: response,
        });
      })
      .catch((error) => {
        if (process.env.NODE_ENV === 'development') {
          console.error(error); //eslint-disable-line
        }
      });
  }

  render() {
    const settings = {
      dots: false,
      arrows: true,
      speed: 500,
      slidesToShow: 5,
      slidesToScroll: 1,
    };

    return (
      <Slider className="logos" {...settings}>
        {this.state.images.map((image, key) => (
          <div key={key} className="logos__item text-center matchHeight">
            <img src={image} alt="" />
          </div>
        ))}
      </Slider>
    );
  }
}

Logos.propTypes = {
  logos: PropTypes.array,
};

Logos.slug = 'fwp_logos_block';

export default Logos;
